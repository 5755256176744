<template>
	<div class="outer-wrapper full-height">
		<div id="top-nav"></div>
		<div class="fixed-top outer-wrapper">
			<router-link :to="{ 
				name: 'Flights Dates',
			}">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>

			<div class="trip-type-holder">
				<input type="radio" v-model="flightsSearch.searchValues.tripType" value="oneway" id="oneway" @click="flightsSearch.searchValues.dates = new Date(formattedDates.start)">
				<label class="oneway-label" for="oneway">One Way</label>
				<input 
					type="radio" 
					v-model="flightsSearch.searchValues.tripType" 
					value="return" 
					id="return" 
					@click="flightsSearch.searchValues.dates = { start: new Date(formattedDates.start), end: '' }">
				<label class="return-label" for="return">Return</label>
			</div>
		</div>
			<!-- <router-link :to="{ name: 'Home' }">
				<img src="@/assets/close-icon.png" alt="close" class="close-icon"/>
			</router-link> -->

			
		<div class="inner-wrapper">
			<locationDropdown 
				v-model="flightsSearch.searchValues.startLocation"
			/>
			<locationDropdown 
				v-model="flightsSearch.searchValues.endLocation"
				class="return"
			/>
			<v-date-picker 
				class="datepicker"
				v-model="flightsSearch.searchValues.dates"
				mode="date" 
				:is-range="isReturn"
				color="customblue"
				:min-date="new Date()"
			>
				<template v-slot="{ isDragging, inputValue, inputEvents }">
					<input
						class="input"
						placeholder="Date"
						:class="{ 'is-selecting': isDragging, 'is-range': isReturn }"
						:value="isReturn ? inputValue.start : inputValue"
						v-on="isReturn ? inputEvents.start : inputEvents"
					/>
					<img v-if="isReturn" src="@/assets/filters/arrow-right.png" class="arrow" />
					<input
						v-if="isReturn"
						placeholder="Return date"
						class="input return"
						:class="{ 'is-selecting': isDragging, 'is-range': isReturn }"
						:value="inputValue.end"
						v-on="inputEvents.end"
					/>
				</template>
			</v-date-picker>
			
			<v-select class="dropdown cabin" v-model="flightsSearch.searchValues.cabinClass" :options="cabinClassOptions" placeholder="Class">
				<template slot="option" slot-scope="option">
					<div class="option">
						<div class="option-name">
							{{ option.label }}
						</div>
					</div>
				</template>
			</v-select>
			
			<div class="direct-only">
				Direct flights only
				<label class="direct-label" for="direct">
					<input type="checkbox" v-model="flightsSearch.searchValues.directOnly" value="direct" id="direct">
					<span class="round-slider"></span>
				</label>
			</div>

			<div class="qty-holder">
				<div class="qty-select">
					<label for="adults">
						Adult(s)
						<div class="years">&gt;12 years</div>
					</label>
					<input v-model="flightsSearch.searchValues.qty.adults" type="number" min="0" max="9" id="adults" v-on:focus="(e) => {e.target.value = '';}" v-on:blur="(e) => {e.target.value = this.flightsSearch.searchValues.qty.adults;}">
				</div>
		
				<div class="qty-select">
					<label for="children">
						Children
						<div class="years">2-11 years</div>
					</label>
					<input v-model="flightsSearch.searchValues.qty.children" type="number" min="0" max="9" id="children" v-on:focus="(e) => {e.target.value = '';}" v-on:blur="(e) => {e.target.value = this.flightsSearch.searchValues.qty.children;}">
				</div>

				<div class="qty-select">
					<label for="infants">
						Infant(s)
						<div class="years">&lt;2 years</div>
					</label>
					<input v-model="flightsSearch.searchValues.qty.infants" type="number" min="0" max="9" id="infants" v-on:focus="(e) => {e.target.value = '';}" v-on:blur="(e) => {e.target.value = this.flightsSearch.searchValues.qty.infants;}">
				</div>
			</div>
			
			<button class="button" v-on:click="submit()" :class="{ disabled: !checkForm }">Search</button>

			<div class="errors-wrapper">
				<div class="errors" v-for="(errors, i) in this.searchFormErrors.values()" :key="i">
					<div v-for="(error, k) in errors" :key="k">
						{{ error }}
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions, mapGetters } from 'vuex'
	import router from '@/router';
	import locationDropdown from '@/components/locationDropdown.vue';
	import helpers from '@/helpers/helpers.js';
	
	export default {
		name: 'flightsSearch',
		data() {
			return { // Some defaults here. These can be fetched via api call from backend.
				cabinClassOptions: [
					{ label: "Economy", code: "economy" },
					{ label: "Premium Economy", code: "premium-economy" },
					{ label: "Business Class", code: "business" },
					{ label: "First Class", code: "first" },
				],
				debounceTimeout: null,
			}
		},
		components: {
			locationDropdown,
		},
		computed: {
			...mapState({
				flightsSearch: state => state.flightsSearch,
			}),
			...mapGetters('flightsSearch', ['formattedDates', 'isReturn', 'searchFormErrors']),
			checkForm() {
				if (!this.searchFormErrors.size > 0) {
					return true;
				}
				return false;
				e.preventDefault();
			},
			tripType() {
				if(this.isReturn) {
					return "Return Trip";
				} else { 
					return "One way";
				}
			},
		},
		methods: {
			...mapActions({
				mapQueryParams: 'flightsSearch/mapQueryParams',
			}),
			submit() {
				if(this.checkForm) {
					// Results are loaded based on state on this next page
					router.push({
						name: 'Flights Results',
						query: {
							trip_id: this.$route.query.trip_id,
							trip_item_id: this.$route.query.trip_item_id,
						}
					})
				}
			},
			formatDate: (date) => helpers.formatDate(date),
			wipeInput(e) {
				e.target.value = "";
			},
			fillInput(e) {
				e.target.value = this.searchValues.qty.infants;
			}
		},
		created() {
			this.flightsSearch.shouldSearch = true;

			this.mapQueryParams();
		}		
	}
</script>

<style scoped>

	.vs__search {
		color: grey;
	}
	.flights.search {
		padding-top: 25px;
	}
	input#oneway, input#return {
		display: inline-block;
		height: 24px;
		margin: 0px;
		position: relative;
		z-index: 1;
		opacity: 0;
	}
	.search-summary {
		color: #118AB2;
		position: relative;
	}
	.datepicker input.is-range {
		width: calc(50% - 16px);
	}
	.datepicker input.is-range.return {
		float: right;
	}
	.datepicker .arrow {
		width: 12px;
		margin-left: 10px;
	}
	
	.direct-only {
		position: relative;
		width: 350px;
		max-width: 100%;
		margin: 0 auto;
		margin-bottom: 26px;
		display: flex;
		justify-content: space-between;
	}
	.direct-only .direct-label {
		position: relative;
		display: inline-block;
		width: 40px;
		height: 20px;
	}
	.direct-only .direct-label input {
		opacity: 0;
		width: 0;
		height: 0;
	}
	.round-slider {
		position: absolute;
		cursor: pointer;
		width: 40px;
		border-radius: 15px;
		top: 0;
		bottom: 0;
		right: 0;
		background-color: #979797;
		-webkit-transition: .4s;
		transition: .4s;
	}
	.round-slider:before {
		content: "";
		position: absolute;
		width: 16px;
		height: 16px;
		left: 2px;
		bottom: 2px;
		background-color: #ffffff;
		border-radius: 50%;
		-webkit-transition: .4s;
		transition: all  .4s ease;
	}
	.direct-only .direct-label input:checked + .round-slider {
		background-color: #118AB9;
	}
	.direct-only .direct-label input:checked + .round-slider:before {
		-webkit-transform: translateX(20px);
		-ms-transform: translateX(20px);
		transform: translateX(20px);
	}
	.qty-holder {
		color: #50555C;
		text-align: center;
	}
	.qty-holder .qty-select {
		display: inline-block;
		margin: 0 20px;
	}
	.qty-holder .qty-select label {
		font-weight: bold;
		font-size: 14px;
	}
	.qty-holder .qty-select .years {
		font-size: 12px;
		font-weight: normal;
	}
	.qty-holder .qty-select input {
		width: 55px;
		height: 55px;
		box-sizing: border-box;
		border-radius: 10px;
		border: 1px solid rgba(60,60,60,.26);
		text-align: center;
		font-size: 16px;
		font-weight: bold;
		color: #50555C;
		margin-top: 12px;
		margin-bottom: 16px;
	}

	.errors-wrapper {
		padding-top: 10px;
		padding-bottom: 10px;
		color: red;
		text-align: center;
	}
	.button {
		margin: 0 auto;
		display: block;
	}

	@media (min-width: 960px) {
		.qty-holder {
			margin-top: 10px;
		}
	}
</style>